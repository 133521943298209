import WebServiceRequest from '../Api/WebServiceRequest'

class UserExamCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserExam/CreateUserExam')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class UserExamGetByExamIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserExam/GetUsersExamByExamId')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class UserExamGetByUserIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserExam/GetUserExamsByUserId')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class UserExamGetMyExamsRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('UserExam/GetMyExams')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class UserExamTakeExamRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('UserExam/TakeExam')
    }

    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

class UserExamGetUserExamByExamIdRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('UserExam/GetUserExamByExamId')
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

export {
    UserExamCreateRequest,
    UserExamGetByExamIdRequest,
    UserExamGetByUserIdRequest,
    UserExamGetMyExamsRequest,
    UserExamTakeExamRequest,
    UserExamGetUserExamByExamIdRequest
}