<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="members">
      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

<!--            <b-col-->
<!--                cols="12"-->
<!--                md="1"-->
<!--                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--            >-->
<!--              <span> تعداد کل : </span>-->
<!--              <span class="mx-1">{{ totalCount }}</span>-->
<!--            </b-col>-->

            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector mx-50 w-50"
              />
              <label>تعداد</label>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="members"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: createDate -->
          <template #cell(createDate)="data">
            <span>
              {{ data.item.createDate.slice(11, 16) }}
              -
              {{ createJalaliDate(data.item.createDate.slice(0, 10)) }}
            </span>
          </template>

          <!-- Column: closed -->
          <template #cell(closed)="data">
            <span v-if="data.item.closed" class=""> بسته شده </span>
            <span v-else class=""> باز </span>
          </template>

          <!-- Column: show -->
          <template #cell(show)="data">
            <b-link :to="{ name: 'apps-exam-member-exams-list', params: { userId: data.item.userId , examId: data.item.examId  } }" class="cursor-pointer">
              <feather-icon size="20" icon="EditIcon"/>
            </b-link>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteExam()"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponGetAllRequest} from "@/libs/Api/Coupon";
import {CouponDeleteRequest} from "@/libs/Api/Coupon";
import Helper from "@/libs/Helper";
import {NumberGetAllRequest} from "@/libs/Api/Number";
import {ConsultantRequestGet} from "@/libs/Api/ConsultantRequest";
import {ExamDeleteRequest, ExamGetmembersRequest} from "@/libs/Api/Exam";
import {UserExamGetByExamIdRequest} from "@/libs/Api/UserExam";

export default {
  name: "ExamMembersList",
  title: "لیست شرکت کنندگان آزمون‌ - پنل ادمین مکس",
  data() {
    return {
      members: null,
      showOverlay: false,
      totalCount: null,
      baseUrl: Helper.baseUrl,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      pageNumber: 1,
      count: 10,
      searchCommand: '',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'userFullName', label: 'کاربر'},
        {key: 'examTitle', label: 'عنوان آزمون'},
        // {key: 'closed', label: 'وضعیت آزمون'},
        {key: 'count', label: 'تعداد دفعات شرکت'},
        // {key: 'createDate', label: 'تاریخ ثبت'},
        {key: 'count', label: 'تعداد شرکت کنندگان'},
        {key: 'show', label: 'نمایش اطلاعات'},
      ],
      defaultCreateData: {
        isDeleted: false,
        productCategoryId: 0,
        parentId: 0,
        name: ''
      },
    }
  },
  async created() {
    await this.getAllMembers(this.pageNumber, this.count)
  },
  methods: {
    async getAllMembers(pageNumber, count) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        examId:_this.$route.params.id,
        page: pageNumber,
        size: count,
      }

      let userExamGetByExamIdRequest = new UserExamGetByExamIdRequest(_this);
      userExamGetByExamIdRequest.setParams(data);
      await userExamGetByExamIdRequest.fetch(function (content) {
        _this.members = content.data.data;
        _this.totalCount = content.data.totalCount;
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getAllMembers(nv, this.count);
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>